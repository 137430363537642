/*===================== 
	Premery bg color 
=====================*/
/* Root */
:root{
	--bg-color: #088194;
	--bg-color-ho: #088194;
}
/*===================== 
	Premery bg color 
=====================*/

/* Hex */
::selection,
.bg-primary,
.meet-ask-row:after,
.dlab-separator.style-skew[class*="style-"]:after,
.dlab-separator.style-skew[class*="style-"]:before,
.dlab-tilte-inner.skew-title:after,
.date-style-2 .post-date,
.date-style-3.skew-date .post-date,
.date-style-3.skew-date .post-date:before,
.overlay-primary-light:after,
.overlay-primary-middle:after,
.overlay-primary-dark:after,
.overlay-primary:after,
.widget-title:after,
.site-button,
.comments-area .comment-form p input[type="submit"],
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.footer-line:after,
.testimonial-1 .quote-left:before,
.testimonial-2 .quote-left:before,
.site-filters .active > [class*="site-button"],
.list-num-count > li:before,
.date-style-4 .post-date strong,
.date-style-3 .post-date,
.date-style-2 .post-date,
#myNavbar li.active a,
.skew-angle:after,
.header-style-4.style-1 .header-nav .nav > li.active a:before,
.header-style-4.style-1 .header-nav .nav > li:hover > a:before,
.dlab-project-left:after,
.service-carousel .owl-prev, 
.service-carousel .owl-next,
.about-us .dlab-tabs .nav-tabs li a.active,
.testimonial-text.bg-primary,
.sidenav ul li a:hover,
.owl-theme.dots-style-2 .owl-dots .owl-dot.active span,
.owl-btn-1.primary .owl-prev, 
.owl-btn-1.primary .owl-next,
.owl-btn-2.primary .owl-prev, 
.owl-btn-2.primary .owl-next,
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active span, 
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover span,
.product-item-size .btn.active,
.ui-widget-header,
.owl-theme.dots-style-3 .owl-dots .owl-dot.active span, 
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover span,
.owl-item.active.center .testimonial-text,
.icon-bx-wraper.hover:hover,
.portfolio-sep,
.portfolio-ic .dlab-img-overlay1:before,
.dlab-box-bg.active .icon-box-btn .site-button,
.dlab-accordion.primary .acod-head a,
.side-bar .widget_tag_cloud a:hover,
.time-line:after,
.line-right:after,
.line-left:after,
.tabs-site-button .nav-tabs li a.active:focus,
.tabs-site-button .nav-tabs li a.active:hover,
.tabs-site-button .nav-tabs li a.active,
.box-item-service:hover .item-service-content,
.box-item-service:hover h5,
.box-item-service:hover .icon-bx-md,
.tp-caption.rev-btn.tc-btnshadow.tp-rs-menulink,
.la-anim-10,
.ind-service-info:hover,
.explore-projects .dlab-box:hover .btn-block,
.ind-ser-bx:hover .btn-block,
.dropdown-item.active, .dropdown-item:active{
    background-color: #088194;
}
:root{
	--bg-color: #088194;
}
/*Hex color :hover */

.site-button:active,
.site-button:hover,
.site-button:focus,
.active > .site-button,
.bg-primary-dark,
.pagination > li > a:hover, 
.pagination > li > span:hover, 
.pagination > li > a:focus, 
.pagination > li > span:focus ,
.pagination > .active > a, 
.pagination > .active > span, 
.pagination > .active > a:hover, 
.pagination > .active > span:hover, 
.pagination > .active > a:focus, 
.pagination > .active > span:focus,
.sidenav .closebtn:hover,
.sidenav .closebtn:focus,
.owl-btn-1.primary .owl-prev:hover, 
.owl-btn-1.primary .owl-next:hover,
.owl-btn-2.primary .owl-prev:hover, 
.owl-btn-2.primary .owl-next:hover{
    background-color: #088194;
}
.about-us .dlab-tabs .nav-tabs li a.active{
	border-right: 4px solid #088194;
}


/* Rgba color */
.dlab-info-has.bg-primary,
.dlab-info-has.bg-secondry:hover,
.tp-bannertimer.tp-bottom,
.header-transparent .is-fixed .main-bar{
    background-color: #088194e6;
}
.main-slider .tp-shapewrapper.ov-tp {
	background-color: #088194bf;
}
/* Box Shadow */
.side-bar .widget_tag_cloud a:hover{
	box-shadow:5px 5px 10px 0 #08819426;
}
.team-section .team-content,
.tp-overlay-video{
	background-color: #088194b3;
}
/*===================== 
	Premery text color 
=====================*/
a,
.text-primary,
.primary li:before,
.breadcrumb-row ul li a,
.header-ind .header-nav .nav > li.active > a,
.header-ind .header-nav .nav > li:hover > a,
.header-nav .nav > li.active > a,
.header-nav .nav > li.current-menu-item > a .header-nav .nav > li .sub-menu li a:hover,
.header-nav .nav > li:hover > a,
.header-nav .nav > li .mega-menu > li ul a:hover,
.header-nav .nav > li .sub-menu li:hover > a,
.nav-dark.header-nav .nav > li .sub-menu li:hover > a,
.nav-dark.header-nav .nav > li .mega-menu > li ul a:hover,
blockquote:before,
ol.comment-list li.comment .reply a,
footer a:active,
footer a:focus,
footer a:hover,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a,
button.scroltop,
.testimonial-1 .testimonial-position,
.testimonial-4 .testimonial-name:after,
.testimonial-2 .testimonial-position,
.testimonial-3 .testimonial-position,
.testimonial-3 .quote-left:before,
.acod-head a:after,
.acod-head a,
.acod-head a:hover,
.acod-head a.collapsed:hover,
.dlab-tabs .nav-tabs > li > a i,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.site-button.outline,
.site-button-link:hover,
.item-info-in ul li a:hover,
.dlab-post-meta.text-primary i,
.dlab-post-meta.text-primary ul li,
.dlab-post-meta.text-primary ul li a,
.dlab-post-meta i,
.comments-area p:before,
.about-8-service .icon-bx-wraper:hover a,
.about-service .icon-bx-wraper:hover a,
.dlab-box-bg .icon-box-btn .site-button{
    color: #088194;
}
@media only screen and (max-width: 991px) {
	.nav-dark.header-nav .nav > li > a:hover, 
	.nav-dark.header-nav .nav > li > a:active, 
	.nav-dark.header-nav .nav > li > a:focus{
		color: #088194;
	}
}
/*===================== 
	Premery border color 
=====================*/
.navbar-toggle,
.testimonial-4 .testimonial-pic,
.testimonial-4:after,
.testimonial-4 [class*="quote-"],
button.scroltop,
blockquote,
.widget_gallery li:hover,
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active, 
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
input[type="checkbox"]:checked + label:before,
input[type="checkbox"] + label:hover:before, 
input[type="radio"] + label:hover:before,
.owl-theme.dots-style-3 .owl-dots .owl-dot.active, 
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover,
.side-bar .widget_tag_cloud a:hover{
    border-color: #088194;
}
.owl-item.active.center .testimonial-8 .testimonial-text:after{
	border-top:15px solid #088194;
}
.dlab-tabs.border-top .nav-tabs > li > a.active,
.header-nav .nav > li .sub-menu, 
.header-nav .nav > li .mega-menu{
	border-top-color: #088194;
}
.port-style1 .port-box{
	border-left: 3px solid #088194;
}
.choseus-tabs .nav li a.active{
	border-bottom: 2px solid #088194;
}
svg.radial-progress:nth-of-type(6n+1) circle { stroke: #088194; }
/*===================== 
	Secondery bg color
 =====================*/
.bg-secondry {
    background-color: #2d3239;
}

/*===================== 
	Secondery text color
 =====================*/
.text-secondry {
    color: #2d3239;
}
.overlay-primary-dark:after{
	/* background-color:#020009; */
}
/* ex */
.choseus-tabs .choses-info-content [class*="col-"],
.about-service .choses-info-content [class*="col-"] {
 border-right: 1px solid #ffffff80;
}
.choseus-tabs .choses-info,
.about-service .choses-info {
 border-top: 1px solid #ffffff80;
}
.choseus-tabs .choseus-tabs .nav {
 border-bottom: 1px solid #ffffff80;
}
.footer-bottom {
 border-top: 1px solid #ffffff4d

}
.subscribe-form input.form-control::-moz-placeholder {
 color:#fff;
}
.overlay-primary .chosesus-content .list-checked.primary li:before,
.choseus-tabs .nav li a {
 color:#fff;
}
.choseus-tabs .nav li a.active {
 border-bottom: 2px solid #fff;
}
footer .bg-primary .widget_categories ul li a, 
footer .bg-primary .widget_archive ul li a, 
footer .bg-primary .widget_meta ul li a, 
footer .bg-primary .widget_pages ul li a, 
footer .bg-primary .widget_recent_comments ul li a, 
footer .bg-primary .widget_nav_menu li a, 
footer .bg-primary .widget_recent_entries ul li a, 
footer .bg-primary .widget_services ul li a,
footer .bg-primary p, footer .bg-primary  strong, footer .bg-primary  b, footer .bg-primary,
.footer-bottom.bg-primary,
.footer-bottom.bg-primary  a {
 color: #fff;
}
.port-style1 .port-box {
 border-left: 3px solid #fff;
}
.header-transparent .main-bar .site-button.primary {
 background-color:#fff;
 color:#000;
}
.header-nav .nav > li.active > a,
.header-nav .nav > li:hover > a,
footer.text-white a:hover{
	color:#fff;
}	
.subscribe-form input{
	background-color:#ffffff4d;
}
.subscribe-form .input-group{
	box-shadow:0 0 0 	2px #ffffff4d;
}


/* Css Gradient */
.home-banner:after,
.new-page,
.tab-market ul li a:after,
.tab-bx,
.site-button.btn-icon i,
.footer-gradient,
.overlay-primary.gradient:after,
.tp-overlay-pera,
.overlay-primary.testimonial-curv-bx:after,
.about-progress .progress-bar,
.pricing-bx .pricingtable-wrapper.style1.active,
.contact-form-bx .input-group:after,
.workspace-bx .dlab-img-overlay1:before{
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#184bd7+0,6c00ae+100 */
	background: #088194; /* Old browsers */
	background: -moz-linear-gradient(65deg, #088194e6 10%, #f2b643e6 90%); /* FF3.6-15 */
	background: -webkit-linear-gradient(65deg, #088194e6 10%,#f2b643e6 90%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(65deg, #088194e6 10%,#f2b643e6 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#184bd7', endColorstr='#6c00ae',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.arrow-bx{
	background-image: url(../../images/arrow-6.png);
}
@media only screen and (max-width: 991px){
	.header-nav .nav > li.active > a,
	.header-nav .nav > li:hover > a{
		color:#000;
	}
}

.text-primary {
    color: #088194!important;
}
.bg-primary {
    background-color: #088194!important;
}

.footer-bottom.bg-primary{
    background-color: #f2b643!important;
}
.site-button:focus{
	color: #fff;
}